import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Card,
  Button,
  Badge,
  Collapse,
  ListGroup,
  Row,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import {
  useAddOrderTrackingMutation,
  useGetSellerOrdersQuery,
  useUpdateOrderMutation,
  useUpdateRejectedOrderBySellerMutation,
} from "../../../Redux/API/ApiSlice";
import { getUserDetails } from "../../../Helper/SessionHelper";
import { countryType, Symbol } from "../../../Helper/constant";
import moment from "moment";
import FormModal from "../../Common/Modal/FormModal";
import axios from "axios";
import { BaseURL } from "../../../Helper/config";
import { LanguageContext } from "../../../context/LanguageContext";
import labels from "../../../translationData/editProfile.json";
import getTranslation from "../../../Helper/getTranslationUtility";
import { FiBox } from "react-icons/fi";
import { useReactToPrint } from "react-to-print";
import SellerReceipt from "../../Common/BillReceipt/SellerReceipt";
const sellersOrderLabels = labels.userDashboard.sellerOrderLabels;

function PendingSellerOrder() {
  const [open, setOpen] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [rejectionReason, setRejectionReason] = useState("");
  // const [timeAgo, setTimeAgo] = useState("");
  const [timeDifferences, setTimeDifferences] = useState({});
  const [reasonOptions] = useState([
    "Busy",
    "Stock Out",
    "Don't Want",
    "Others",
  ]);
  const [customReason, setCustomReason] = useState("");

  const receiptRef = useRef();

  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);

  const [updateOrder, { isError, isSuccess }] = useUpdateOrderMutation();
  const [updateRejectedOrder] = useUpdateRejectedOrderBySellerMutation();

  const [showReceipt, setShowReceipt] = useState(false);
  const [recieveData, setRecieveData] = useState();

  const toggleDetails = (orderId) => {
    setOpen((prev) => ({ ...prev, [orderId]: !prev[orderId] }));
  };

  const handleRejectClick = (data) => {

    const id = data._id;
    setSelectedOrderId(id);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false); // Close the modal
    setRejectionReason(""); // Reset the rejection reason
    setSelectedOrderId(null); // Reset the selected order ID
    setCustomReason(""); // Reset the custom reason
  };


  const [handleTrackOrder] = useAddOrderTrackingMutation()
  const handleRejectOrder = async () => {
    console.log(
      `Order ${selectedOrderId} rejected for reason: ${rejectionReason}`
    );

    updateRejectedOrder({
      id: selectedOrderId,
      data: {
        rejectionReason:
          rejectionReason === "Others" ? customReason : rejectionReason,
        orderStatusId: "64cf5d349aae4565fb21d9e1",
        orderStatus: "Rejected By Seller",
      },
    });

    await handleTrackOrder({
      data: {
        orderID: selectedOrderId,
        orderStatusID: "64cf5d349aae4565fb21d9e1",
        comment: "Rejected By Seller",
      }
    });

    // await axios.post(`${BaseURL}/create-coupon`,{

    // })
    handleModalClose();
  };

  const handleAccept = async (data) => {
    console.log(data);
    const id = data._id;
    await updateOrder({
      id: id,
      data: {
        orderStatusId: "64cf5d349aae4565fb21d9e1",
        orderStatus: "Accepted By Seller",
      },
    });
    const ordertra = await handleTrackOrder({
      data: {
        orderID: id,
        orderStatusID: "64cf5d349aae4565fb21d9e1",
        comment: "Accepted By Seller",
      }
    });
    console.log(ordertra);


  };

  const { data, isLoading, error } = useGetSellerOrdersQuery({
    id: getUserDetails()?.sellerIDInfo[0]?._id,
    orderStatus: undefined,
    page: 1,
    limit: 1000,
  });

  const SkeletonLoader = () => (
    <div
      style={{
        padding: "20px",
        borderRadius: "16px",
        background: "linear-gradient(135deg, #f5f5f5, #e0e0e0)",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "24px",
          borderRadius: "5px",
          backgroundColor: "#ddd",
          marginBottom: "10px",
        }}
      ></div>
      <div
        style={{
          width: "60%",
          height: "24px",
          borderRadius: "5px",
          backgroundColor: "#ddd",
          marginBottom: "20px",
        }}
      ></div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            width: "30%",
            height: "16px",
            borderRadius: "5px",
            backgroundColor: "#ddd",
          }}
        ></div>
        <div
          style={{
            width: "20%",
            height: "16px",
            borderRadius: "5px",
            backgroundColor: "#ddd",
          }}
        ></div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "30px",
        }}
      >
        <div
          style={{
            width: "100px",
            height: "36px",
            borderRadius: "20px",
            backgroundColor: "#ddd",
            marginRight: "10px",
          }}
        ></div>
        <div
          style={{
            width: "100px",
            height: "36px",
            borderRadius: "20px",
            backgroundColor: "#ddd",
          }}
        ></div>
      </div>
    </div>
  );

  // Move useReactToPrint to the top-level of the component
  const handlePrintMini = useReactToPrint({
    content: () => receiptRef.current,
    pageStyle: `
      @media print {
        @page { size: 80mm 200mm; margin: 0; }
        body { margin: 0; }
        .receipt {
          font-size: 10px; /* Adjust font size for smaller print */
        }
      }
    `,
  });

  const handlePrintMiniClick = (item) => {
    setRecieveData(item);
    setShowReceipt(true);
    setTimeout(() => {
      handlePrintMini(); // Call the print function directly
      setShowReceipt(false);
    }, 10);
  };



  const orderN = getTranslation(
    "orderN.tabTitle",
    currentLanguage,
    sellersOrderLabels
  );
  const total = getTranslation(
    "total.tabTitle",
    currentLanguage,
    sellersOrderLabels
  );
  const statuts = getTranslation(
    "statuts.tabTitle",
    currentLanguage,
    sellersOrderLabels
  );
  const customerInformation = getTranslation(
    "customerInformation.tabTitle",
    currentLanguage,
    sellersOrderLabels
  );
  const hideDetails = getTranslation(
    "hideDetails.tabTitle",
    currentLanguage,
    sellersOrderLabels
  );
  const showDetails = getTranslation(
    "showDetails.tabTitle",
    currentLanguage,
    sellersOrderLabels
  );
  const Accept = getTranslation(
    "Accept.tabTitle",
    currentLanguage,
    sellersOrderLabels
  );
  const Reject = getTranslation(
    "Reject.tabTitle",
    currentLanguage,
    sellersOrderLabels
  );
  const namee = getTranslation(
    "namee.tabTitle",
    currentLanguage,
    sellersOrderLabels
  );
  const phhone = getTranslation(
    "phhone.tabTitle",
    currentLanguage,
    sellersOrderLabels
  );
  const adcddress = getTranslation(
    "adcddress.tabTitle",
    currentLanguage,
    sellersOrderLabels
  );
  const ppaymentMethod = getTranslation(
    "ppaymentMethod.tabTitle",
    currentLanguage,
    sellersOrderLabels
  );
  const orderTtype = getTranslation(
    "orderTtype.tabTitle",
    currentLanguage,
    sellersOrderLabels
  );
  const deeliveryDate = getTranslation(
    "deeliveryDate.tabTitle",
    currentLanguage,
    sellersOrderLabels
  );
  const paymentDate = getTranslation(
    "paymentDate.tabTitle",
    currentLanguage,
    sellersOrderLabels
  );
  const SelectsReason = getTranslation(
    "SelectsReason.tabTitle",
    currentLanguage,
    sellersOrderLabels
  );
  const SelectsaReason = getTranslation(
    "SelectsaReason.tabTitle",
    currentLanguage,
    sellersOrderLabels
  );

  const OrderMenu = getTranslation(
    "OrderMenu.tabTitle",
    currentLanguage,
    sellersOrderLabels
  );
  const getTimeDifference = (createdAt) => {
    const now = moment();
    const orderTime = moment(createdAt);
    const duration = moment.duration(now.diff(orderTime));

    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    if (hours > 0) {
      return `${hours}h ${minutes}min`;
    } else if (minutes > 0) {
      return `${minutes}min ${seconds}s`;
    } else {
      return `${seconds}s`;
    }
  };

  // Update time differences every second
  useEffect(() => {
    const interval = setInterval(() => {
      const newTimeDifferences = {};
      data?.data?.orders[0]?.orderinfo?.forEach((item) => {
        newTimeDifferences[item.orderNumber] = getTimeDifference(item.createdAt);
      });
      setTimeDifferences(newTimeDifferences);
    }, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [data]);

  if (
    !data?.data?.ordersound < [0]?.orderinfo ||
    data?.data?.orders[0]?.orderinfo.length === 0
  ) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[300px] text-center bg-gray-50 p-6 rounded-lg shadow-md">
        <FiBox size={40} className="text-gray-400 text-6xl mb-4" />
        <h2 className="text-xl font-semibold text-gray-700">No Orders Found</h2>
        <p className="text-gray-500 mt-2">
          It looks like you don’t have any orders yet. Start browsing and place
          your first order!
        </p>
      </div>
    );
  }

  return (
    <>
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <>
          {data?.data?.orders[0]?.orderinfo?.map((item) => (
            <Card
              className="mb-4 order-card"
              style={{
                border: "none",
                borderRadius: "16px",
                transition: "transform 0.3s ease-in-out ",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.02)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <Card.Header
                className="order-card-header"
                style={{ background: "#1E466A", color: "white" }}
              >
                <h5 className="text-white">
                  {orderN} {item?.orderNumber}
                </h5>
                <div className="dliver-order-bx d-flex align-items-center">
                  <div>
                    <button
                      type="button"
                      class="btn btn-dark"
                      onClick={() => handlePrintMiniClick(item)}
                    >
                      Invoice
                    </button>
                    {/* Conditionally render BillReceipt for printing */}
                    {showReceipt && (
                      <SellerReceipt ref={receiptRef} data={recieveData} />
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="fw-bold me-2">🕒 Time Since Order:</span>
                  <span className="badge bg-light text-secondary px-3 py-2">
                    {getTimeDifference(item?.createdAt)}
                  </span>
                </div>
                <Badge className="status-badge">Pending</Badge>
              </Card.Header>

              <Card.Body className="order-card-body">
                <Row className="align-items-center">
                  <Col
                    md={6}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <h6>
                      {total}:{" "}
                      <span className="total-amount">
                        {countryType === "bdt" ? Symbol.bdt : Symbol.usd}
                        {item?.orderTotalAmount}
                      </span>
                    </h6>
                    <Button
                      variant="link"
                      onClick={() => toggleDetails(item?.orderNumber)}
                      aria-expanded={open[item?.orderNumber]}
                      className="toggle-details-btn"
                    >
                      {open[item?.orderNumber]
                        ? `${hideDetails}`
                        : `${showDetails}`}
                    </Button>
                  </Col>
                  <Col md={6} className="text-end">
                    <h6>
                      {statuts}: <span>{item?.orderStatus}</span>
                    </h6>
                  </Col>
                </Row>

                <Collapse in={open[item?.orderNumber]}>
                  <div className="order-details">
                    <Row className="mt-4">
                      <Col md={6}>

                        <Card.Title>{OrderMenu}</Card.Title>
                        <Card.Text>
                          {item.orderItems.map((i) => (
                            <div className="d-flex align-items-center justify-content-xl-center justify-content-lg-start  mb-2">
                              <img
                                className="me-2 img-fluid rounded"
                                src={i?.foodImage[0]?.small?.imageUrl}
                                alt=""
                                width="50px"
                                height="50px"
                              />
                              <div>
                                <h6 className="font-w600 text-nowrap mb-0">
                                  {i?.foodName}
                                </h6>
                                <p className="mb-0">x{i?.foodQty}</p>
                              </div>
                              <h6 className="text-primary mb-0 ps-3 ms-auto">
                                {countryType === "bdt"
                                  ? Symbol.bdt
                                  : Symbol.usd}
                                {i?.foodPrice}
                              </h6>
                            </div>
                          ))}
                        </Card.Text>


                        <Card.Title>{customerInformation}</Card.Title>
                        <Card.Text>
                          <strong>{namee}:</strong>{" "}
                          {item?.orderDeliveryAddress?.Name}
                          <br />
                          <strong>{phhone}:</strong>{" "}
                          {item?.orderDeliveryAddress?.phoneNumber}
                          <br />
                          <strong>{adcddress}:</strong>{" "}
                          {item?.orderDeliveryAddress?.addressText?.length > 20
                            ? `${item?.orderDeliveryAddress?.addressText?.slice(
                              0,
                              15
                            )}...`
                            : item?.orderDeliveryAddress?.addressText}
                        </Card.Text>
                      </Col>

                      <Col md={6}>
                        <Card.Title>{ppaymentMethod}</Card.Title>
                        <Card.Text>{item?.paymentMethod || "N/A"}</Card.Text>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Card.Title>{orderTtype}</Card.Title>
                        <Card.Text>{item?.OrderLabel}</Card.Text>
                      </Col>

                      <Col md={6}>
                        <Card.Title>{deeliveryDate}</Card.Title>
                        <Card.Text>
                          {moment(item?.deliveryDateTime).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        </Card.Text>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Card.Title>{paymentDate}</Card.Title>
                        <Card.Text>
                          {moment(item?.paymentDate).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        </Card.Text>
                      </Col>

                      <Col md={6}>
                        <Card.Title>{statuts}</Card.Title>
                        <Card.Text>{item?.orderStatus}</Card.Text>
                      </Col>
                    </Row>
                  </div>
                </Collapse>
              </Card.Body>

              <Card.Footer className="order-card-footer">
                <div className="d-flex justify-content-end">
                  <Button
                    variant="danger"
                    onClick={() => handleRejectClick(item)}
                  >
                    {Reject}
                  </Button>
                  <Button
                    variant="success"
                    onClick={() => handleAccept(item)}
                    className="ms-2"
                  >
                    {Accept}
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          ))}
        </>
      )}
      <FormModal
        show={showModal}
        handleClose={handleModalClose}
        title="Reject Order"
        actionButtonTitle="Reject Order"
        handleActionButton={handleRejectOrder}
        actionButtonDisabled={!rejectionReason}
      >
        <Form.Group>
          <Form.Label>{SelectsReason}</Form.Label>
          <Form.Control
            as="select"
            value={rejectionReason}
            onChange={(e) => {
              setRejectionReason(e.target.value);
              if (e.target.value !== "Others") setCustomReason("");
            }}
          >
            <option value="">{SelectsaReason}</option>
            {reasonOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
            <option value="Others">Others</option>
          </Form.Control>
        </Form.Group>
        {rejectionReason === "Others" && (
          <Form.Group className="mt-2">
            <Form.Label>Specify Reason</Form.Label>
            <Form.Control
              type="text"
              value={customReason}
              onChange={(e) => setCustomReason(e.target.value)}
            />
          </Form.Group>
        )}
      </FormModal>
    </>
  );
}

export default PendingSellerOrder;
