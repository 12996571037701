import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Accordion, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "./upload.css";
import axios from "axios";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import Swal from "sweetalert2";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BaseURL } from "../../../Helper/config";
import { getUserDetails } from "../../../Helper/SessionHelper";
import {
  ImageUploader,
  onUploadProgress,
} from "../../../ImageUploader/ImageUploader";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../Redux/feature/ImageLoader/ImageLoaderSlice";
import Loader from "../../Common/Loader";
import UploaderModel from "../../Common/UploaderModel/UploaderModel";
import { useForm } from "react-hook-form";

import labels from "../../../translationData/food.json";
import getTranslation from "../../../Helper/getTranslationUtility";
import { LanguageContext } from "../../../context/LanguageContext";

const CreateFood = () => {
  const sellerID = getUserDetails().sellerIDInfo[0]?._id;
  const animatedComponents = makeAnimated();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch
  } = useForm();
  const [catID, setCatID] = useState([]);
  const [cat, setcat] = useState();
  const [portionId, setPortionId] = useState([]);
  const [subcat, setSubCat] = useState([]);
  const [Tag, setTag] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);
  const [taq, setAdditionalTaq] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [insCatData, setInsCat] = useState("INSTANT");
  const [metaImage, setMetaImage] = useState([]);
  const [availableDaysData, setAvailableDays] = useState();
  const DaysData = [
    { value: 1, label: "SATURDAY" },
    { value: 2, label: "SUNDAY" },
    { value: 3, label: "MONDAY" },
    { value: 4, label: "TUESDAY" },
    { value: 5, label: "WEDNESDAY" },
    { value: 6, label: "THURSDAY" },
    { value: 7, label: "FRIDAY" },
  ];

  const [selectedDays, setSelectedDays] = useState({
    MON: false,
    TUE: false,
    WED: false,
    THU: false,
    FRI: false,
    SAT: false,
    SUN: false,
  });
  const handleDayClick = (day) => {
    setSelectedDays({
      ...selectedDays,
      [day]: !selectedDays[day],
    });
  };
  const [applyEveryWeek, setApplyEveryWeek] = useState(false);
  const handleApplyEveryWeekToggle = () => {
    setApplyEveryWeek(!applyEveryWeek);
  };

  // console.log("Days:", days);
  //      meta image handle
  //--------------------------------------
  const handleMetaImage = (event) => {
    // console.log("meta image");
    const fileList = Array.from(event.target.files);
    setMetaImage(fileList);
    // console.log(metaImage, fileList, "meta image");
  };

  //----------------------------------------
  const handleImageDeleteFood = (index) => {
    const updatedImages = [...selectedImage];
    updatedImages.splice(index, 1);
    // console.log(selectedImageAmazingDish);
    setSelectedImage(updatedImages);
  };



  // console.log(selectedDate, "date select");
  // useEffect(() => {
  //   axios
  //     .get(`${BaseURL}/get-additionaltags`)
  //     .then((res) => {
  //       setAdditionalTaq(res.data.data);
  //     })
  //     .catch((Err) => {
  //       console.log(Err);
  //     });
  //   const getCat = (e) => {
  //     axios
  //       .get(`${BaseURL}/get-category`)
  //       .then((res) => setCatID(res.data.data))
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };
  //   getCat();
  //   const getPortion = (e) => {
  //     axios
  //       .get(`${BaseURL}/get-portionSizeUnit`)
  //       .then((res) => setPortionId(res.data.data))
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };
  //   getPortion();
  //   const getSubcat = async () => {
  //     let url;
  //     if (cat) {
  //       url = `${BaseURL}/get-subcategories-by-category-by-seller/${sellerID}/${cat}`;
  //     } else {
  //       url = `${BaseURL}/get-subcategories-by-category-by-seller/${sellerID}`;
  //     }
  //     await axios
  //       .get(url)
  //       .then((res) => {
  //         console.log(res.data.data);
  //         setSubCat(res?.data?.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };
  //   getSubcat();
  // }, [cat]);


  useEffect(() => {
    // Retrieve the token from local storage
    const token = localStorage.getItem("Token");
    const headers = token ? { token: `${token}` } : {};

    // Fetch Additional Tags
    axios
      .get(`${BaseURL}/get-additionaltags`, { headers })
      .then((res) => {
        setAdditionalTaq(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // Fetch Categories
    const getCat = () => {
      axios
        .get(`${BaseURL}/get-category`, { headers })
        .then((res) => setCatID(res.data.data))
        .catch((err) => {
          console.log(err);
        });
    };
    getCat();

    // Fetch Portion Size Units
    const getPortion = () => {
      axios
        .get(`${BaseURL}/get-portionSizeUnit`, { headers })
        .then((res) => setPortionId(res.data.data))
        .catch((err) => {
          console.log(err);
        });
    };
    getPortion();

    // Fetch Subcategories by Category and Seller
    const getSubcat = async () => {
      let url;
      if (cat) {
        url = `${BaseURL}/get-subcategories-by-category-by-seller/${sellerID}/${cat}`;
      } else {
        url = `${BaseURL}/get-subcategories-by-category-by-seller/${sellerID}`;
      }

      await axios
        .get(url, { headers })
        .then((res) => {
          // console.log(res.data.data);
          setSubCat(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getSubcat();
  }, [cat]);

  const handleCheck = (e) => {
    // console.log(e.target.checked, "chk");
    if (e.target.checked) {
      setTag((oldArray) => [...oldArray, e.target.value]);
    } else {
      removeCities(e);
      console.log(Tag);
    }
  };
  const removeCities = (e) => {
    setTag([...Tag.filter((Tag) => Tag !== e.target.value)]);
  };

  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);

    setSelectedImage([...selectedImage, ...fileList]);
  };

  //start handle instant catering on change method
  const handleInCa = (e) => {
    const value = e.target.value;
    // console.log(value);
    setInsCat(value);
  };

  const [selectedIsDiscountAnyOption, setSelectedIsDiscountAnyOption] =
    useState("");

  const handleIsDiscountAnyOptionChange = (event) => {
    const selectedDiscountOption = event.target.value;
    // console.log(selectedDiscountOption,"discount");

    setSelectedIsDiscountAnyOption(selectedDiscountOption);

    setDiscountPrice("");
    setDiscountPercentage("");
    setFoodPriceAfterDiscount("");
  };
  const [editorData, setEditorData] = useState();
  const [foodPrice, setFoodPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [foodPriceAfterDiscount, setFoodPriceAfterDiscount] = useState("");

  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);

  const handleDiscountPrice = (event) => {
    const price = parseFloat(event.target.value);
    // console.log(price);
    setDiscountPrice(price);
    setDiscountPercentage("");
    setFoodPriceAfterDiscount("");
    calculatePriceAfterDiscount(price);
  };

  const handleDiscountPercentage = (event) => {
    const percentage = parseFloat(event.target.value);
    setDiscountPercentage(percentage);
    setDiscountPrice("");
    setFoodPriceAfterDiscount("");
    calculatePriceAfterDiscount(percentage);
  };
  const calculatePriceAfterDiscount = (updatedDiscountPrice) => {
    if (
      !isNaN(foodPrice) &&
      !isNaN(discountPercentage) &&
      discountPercentage > 0
    ) {
      const discountAmount = (foodPrice * updatedDiscountPrice) / 100;
      const discountedPrice = foodPrice - discountAmount;
      setFoodPriceAfterDiscount(discountedPrice);
    } else if (
      !isNaN(foodPrice) &&
      !isNaN(updatedDiscountPrice) &&
      updatedDiscountPrice > 0
    ) {
      const discountedPrice = foodPrice - updatedDiscountPrice;
      setFoodPriceAfterDiscount(discountedPrice);
    } else {
      setFoodPriceAfterDiscount("");
    }
  };

  //end handle instant catering on change method
  const dispatch = useDispatch();
  //start create food method
  const handleCreateFood = async (value) => {
    // e.preventDefault();
    // const form = e.target;

    const foodType = value.foodType;
    const FoodName = value.name;
    // const Slug = value.slug;
    // const description = value.description;
    const description = editorData;

    // কয়জনের জন্য বিক্রী করবেন?
    const Quantity = value.quantity;
    const Price = value.price;

    const foodIsDiscountAny = selectedIsDiscountAnyOption;
    const discountedPrice = discountPrice;
    const discountedPercentage = discountPercentage;
    const foodPriceAfterDiscounted = foodPriceAfterDiscount;
    // প্রতিজনে খাবারের পরিমান কতটুকু?
    const Portion = value.portionSize;

    const Category = value.category;
    const Subcategory = value.subcategory;
    const StartDate = value.startdate;
    const EndDate = value.enddate;

    // const additionalInfo = value.foodAdditionalInfo;
    // const FoodMinQty = value.minimumquantity;

    // const food_creation_date = value.food_creation_date;
    // const food_creation_time = value.food_creation_time;

    const metaTitle = value.metaTitle;
    const metaDescription = value.metaDescription;
    const metaKeyword = value.metaKeywords;


    const FoodData = {
      sellerID: getUserDetails().sellerIDInfo[0]?._id,

      categoryID: Category,


      sellerName: getUserDetails().sellerIDInfo[0]?.userFullName,
      foodName: FoodName,
      foodDescription: description,
      foodType: foodType,

      foodImage: [],
      foodVideoURL: value.foodVideoURL,

      foodQty: Quantity,
      foodPrice: Price,
      foodIsDiscountAny: selectedIsDiscountAnyOption,
      foodDiscountPrice: discountPrice,
      foodDiscountPercentage: discountPercentage,
      foodPriceAfterDiscount: foodPriceAfterDiscount ? foodPriceAfterDiscount : Price,

      foodPortionSize: Portion,

      foodAdditionalTags: Tag,
      seo: {
        metaTitle: metaTitle,
        metaDescription: metaDescription,
        metaKeywords: metaKeyword,
        metaImage: [],
      },

    };
    console.log(FoodData, "foodData");

    if (Subcategory) {
      // subCategoryID: Subcategory,
      FoodData["subCategoryID"] = Subcategory;
    }
    if (foodType === "INSTANT") {
      const activatedForHours = value.foodActiveForHours;
      FoodData["foodActiveForHours"] = activatedForHours;
    } else if (foodType === "PREORDER") {
      FoodData["foodDiscountStartDate"] = StartDate;
      FoodData["foodDiscountEndDate"] = EndDate;
      FoodData["foodOrderBeforeTime"] = value.foodOrderBeforeTime;
    }
    if (foodType === "PREORDER") {
      FoodData["foodAvailableDays"] = selectedDays;
      FoodData["foodAvailableSameDaysEveryWeek"] = applyEveryWeek;
      FoodData["foodOrderBeforeTime"] = applyEveryWeek;
      FoodData["foodOrderBeforeTime"] = value.foodOrderBeforeTime;
    }


    if (metaImage) {

      dispatch(setLoading(true));
      await ImageUploader(getUserDetails(), "SEO/MetaImage/", metaImage).then(
        (data) => {
          if (data.status === "Success") {
            // console.log(data, "meta image");
            FoodData.seo.metaImage = data.images;
          } else {
            dispatch(setLoading(false));
          }
        }
      );
    }
    dispatch(setLoading(true));
    // console.log(getUserDetails(getUserDetails(),"Meta/",));
    const imagePromise = ImageUploader(
      getUserDetails(),
      "Food/",
      selectedImage
    ).then((data) => {
      if (data.status === "Success") {
        FoodData.foodImage = data.images;
        dispatch(setLoading(false));
      }
    });


    // Promise.resolve for Create Food API
    Promise.resolve(imagePromise).then(async () => {
      // Retrieve the token from local storage
      const token = localStorage.getItem("Token");
      const headers = token ? { token: `${token}` } : {};

      await axios
        .post(`${BaseURL}/create-food`, FoodData, { headers })
        .then((res) => {
          // console.log(res.data, "ll");
          dispatch(setLoading(false));

          if (res.data.status === "Success") {
            Swal.fire({
              icon: "success",
              title: "Food created successfully!!!",
              showConfirmButton: false,
              timer: 1500,
            });
            reset();
          } else {
            Swal.fire({
              icon: "error",
              title: "Food does not Create!!!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });

  };

  const loader = useSelector((state) => state.ImageLoader.loading);

  let percentage = useSelector((state) => state.ImageLoader.percentage);


  // Function to change the language
  const changeLanguage = (language) => {
    setCurrentLanguage(language);
  };

  //Tab labels:
  const title = getTranslation(
    "title",
    currentLanguage,
    labels
  );
  const foodType = getTranslation(
    "foodType",
    currentLanguage,
    labels
  );
  const Instant = getTranslation(
    "Instant",
    currentLanguage,
    labels
  );
  const PreOrder = getTranslation(
    "PreOrder",
    currentLanguage,
    labels
  );
  const Catering = getTranslation(
    "Catering",
    currentLanguage,
    labels
  );
  const ThisFieldisrequired = getTranslation(
    "ThisFieldisrequired",
    currentLanguage,
    labels
  );
  const foodName = getTranslation(
    "foodName",
    currentLanguage,
    labels
  );
  const foodPhoto = getTranslation(
    "foodPhoto",
    currentLanguage,
    labels
  );
  const CreatFood = getTranslation(
    "CreatFood",
    currentLanguage,
    labels
  );
  const dragDrop = getTranslation(
    "dragDrop",
    currentLanguage,
    labels
  );
  const upload = getTranslation(
    "upload",
    currentLanguage,
    labels
  );
  const stepVideos = getTranslation(
    "stepVideos",
    currentLanguage,
    labels
  );
  const foodDescription = getTranslation(
    "foodDescription",
    currentLanguage,
    labels
  );
  const available = getTranslation(
    "available",
    currentLanguage,
    labels
  );
  const foodQuantity = getTranslation(
    "foodQuantity",
    currentLanguage,
    labels
  );
  const portion = getTranslation(
    "portion",
    currentLanguage,
    labels
  );
  const foodpprice = getTranslation(
    "foodPrice",
    currentLanguage,
    labels
  );
  const foodIsDiscountAny = getTranslation(
    "foodIsDiscountAny",
    currentLanguage,
    labels
  );
  const foodIsDiscountOptionNoDiscount = getTranslation(
    "foodIsDiscountOptionNoDiscount",
    currentLanguage,
    labels
  );
  const foodIsDiscountOptionPriceDiscountTk = getTranslation(
    "foodIsDiscountOptionPriceDiscountTk",
    currentLanguage,
    labels
  );
  const foodDiscountPrice = getTranslation(
    "foodDiscountPrice",
    currentLanguage,
    labels
  );
  const foodIsDiscountOptionPriceDiscountPercentage = getTranslation(
    "foodIsDiscountOptionPriceDiscountPercentage",
    currentLanguage,
    labels
  );
  const foodPriceAerDiscount = getTranslation(
    "foodPriceAfterDiscount",
    currentLanguage,
    labels
  );
  const foodDiscountPercentage = getTranslation(
    "foodDiscountPercentage",
    currentLanguage,
    labels
  );
  const discountStart = getTranslation(
    "discountStart",
    currentLanguage,
    labels
  );
  const discountEnd = getTranslation(
    "discountEnd",
    currentLanguage,
    labels
  );
  const category = getTranslation(
    "category",
    currentLanguage,
    labels
  );
  const metatitle = getTranslation(
    "metatitle",
    currentLanguage,
    labels
  );
  const metaDescription = getTranslation(
    "metaDescription",
    currentLanguage,
    labels
  );
  const metaKeywords = getTranslation(
    "metaKeywords",
    currentLanguage,
    labels
  );
  const metaImg = getTranslation(
    "metaImg",
    currentLanguage,
    labels
  );
  const itemactive = getTranslation(
    "itemactive",
    currentLanguage,
    labels
  );
  const twelvhrs = getTranslation(
    "twelvhrs",
    currentLanguage,
    labels
  );
  const twentyhrs = getTranslation(
    "twentyhrs",
    currentLanguage,
    labels
  );
  const addditional = getTranslation(
    "addditional",
    currentLanguage,
    labels
  );
  const thirtyhrs = getTranslation(
    "thirtyhrs",
    currentLanguage,
    labels
  );
  const subcateg = getTranslation(
    "subCat",
    currentLanguage,
    labels
  );



  return (
    <>
      <UploaderModel loader={loader} />
      <div className='container d-flex create-food-container'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-title'>{title}</h4>
            </div>
            <div className='card-body'>
              <div className='basic-form'>
                <form onSubmit={handleSubmit(handleCreateFood)}>
                  <div className=' form-group mb-3'>
                    <label>{foodType} </label>
                    <select
                      // name='foodType'
                      {...register("foodType", { required: true })}
                      className='form-control input-default '
                      defaultValue='INSTANT'
                      onChange={handleInCa}
                    >
                      <option value='INSTANT'>{Instant}</option>
                      <option value='PREORDER'>
                        {PreOrder}
                      </option>
                      <option value='CATERING'>
                        {Catering}
                      </option>
                      <option value='READYMADE'>
                       Ready Made
                      </option>
                    
                    </select>
                    {errors.foodType && (
                      <span className='text-danger'>
                        {ThisFieldisrequired}
                      </span>
                    )}
                  </div>

                  {/* {insCatData !== "INSTANT" ? (
                      <div className="row">
                        <div className="  form-group mb-3 col-lg-4">
                          <label>Date: </label>
                          <input
                            type="date"
                            className="form-control input-default "
                            placeholder={"Food Creation Date"}
                            label="food creation date"
                            // name='food creation date'
                            {...register("food_creation_date", {
                              required: false,
                            })}
                          />
                        </div>
                        <div className="  form-group mb-3 col-lg-4">
                          <label>Time: </label>
                          <input
                            type="time"
                            className="form-control input-default "
                            placeholder={"Food Creation Time"}
                            label="food creation time"
                            // name='food creation time'
                            {...register("food_creation_time", {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )} */}

                  <div className='form-group mb-3'>
                    <label>{foodName}</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder={foodName}
                      label='Name:'
                      name='name'
                      {...register("name", { required: true })}
                    />
                    {errors.name && (
                      <span className='text-danger'>{ThisFieldisrequired}</span>
                    )}
                  </div>

                  <div
                    className='row justify-content-center mt-3 mb-5 '
                  // style={{ marginLeft: "30%" }}
                  >
                    <div className='col-12'>
                      <label>{foodPhoto}</label>
                      <div className='row gap-2'>
                        {/* <div className=' '> */}
                        {selectedImage.length <= 6 ? (
                          <>
                            {" "}
                            {[...Array(6)].map((item, index) => (
                              <div className='col'>
                                <div className='image-box col justify-content-center align-items-center'>
                                  {selectedImage[index] &&
                                    selectedImage.length > 0 && (
                                      <div className='img'>
                                        <img
                                          alt=''
                                        
                                          src={URL.createObjectURL(
                                            selectedImage[index]
                                          )}
                                        />
                                        <div className='img-delete'>
                                          <AiFillDelete
                                            size={20}
                                            color='red'
                                            onClick={() =>
                                              handleImageDeleteFood(index)
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <>
                            {selectedImage.map((item, index) => (
                              <div className='col'>
                                <div className='image-box d-flex justify-content-center align-items-center'>
                                  {selectedImage[index] &&
                                    selectedImage.length > 0 && (
                                      <div className='img'>
                                        <img
                                          alt=''
                                          // className="img-fluid"
                                          src={URL.createObjectURL(
                                            selectedImage[index]
                                          )}
                                        />
                                        <div className='img-delete'>
                                          <AiFillDelete
                                            size={20}
                                            color='red'
                                            onClick={() =>
                                              handleImageDeleteFood(index)
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                        {/* </div> */}
                      </div>
                    </div>

                    <div>
                     
                    </div>

                    <br />
                    <br />

                    <div id='form-file-upload'>
                      <input
                        type='file'
                        id='input-file-upload'
                        multiple
                        onChange={handleFileChange}
                      />
                      <label id='label-file-upload' htmlFor='input-file-upload'>
                        <div>
                          <p>{dragDrop}</p>
                          <span className='upload-button'>{upload}</span>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div className='form-group mb-3'>
                    <label className='form-label'>{stepVideos}</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter Video URL'
                      // name="foodVideoURL"
                      {...register("foodVideoURL", { required: false })}
                    />
                  </div>

                  <div className='form-group mb-3'>
                    <label>{foodDescription}</label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={{
                        removePlugins: [
                          "EasyImage",
                          "ImageUpload",
                          "MediaEmbed",
                        ],
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setEditorData(data);
                      }}
                      style={{ height: 20 }}
                    />
                  </div>

                  {insCatData !== "INSTANT" &&
                    insCatData !== "CATERING" &&
                    insCatData === "PREORDER" ? (
                    <div className='row'>
                      <div className=' form-group mb-3 col-lg-6'>
                        <label>{available}</label>

                        <div className='days-container'>
                          {Object.keys(selectedDays).map((day) => (
                            <div
                              key={day}
                              className={`day ${selectedDays[day] ? "day-selected" : ""
                                }`}
                              onClick={() => handleDayClick(day)}
                            >
                              {day}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className=' form-group mb-3 col-lg-3'>
                        <label>
                          <input
                            type='checkbox'
                            checked={applyEveryWeek}
                            onChange={handleApplyEveryWeekToggle}
                          />
                          Apply same days for every week
                        </label>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className='row'>
                    <div className='form-group mb-3 col-lg-4'>
                      <label>{foodQuantity}</label>
                      <input
                        type='number'
                        className='form-control input-default '
                        placeholder={foodQuantity}
                        label='quantity:'
                        min={1}
                        // name='quantity'
                        {...register("quantity", { required: true })}
                      // value={data?.quantity}
                      // onChange={handleChange}
                      />
                      {errors.quantity && (
                        <span className='text-danger'>
                          {ThisFieldisrequired}
                        </span>
                      )}
                    </div>

                    <div className='form-group mb-3 col-lg-4'>
                      <label>{portion}</label>
                      <input
                        type='text'
                        className='form-control input-default '
                        placeholder={portion}
                        name='portionSize'
                        {...register("portionSize", { required: false })}
                      />
                    </div>

                    <div className='form-group mb-3 col-lg-4'>
                      <label>{foodpprice} *</label>
                      <input
                        type='number'
                        className='form-control input-default '
                        placeholder={foodpprice}
                        label='price:'
                        min={1}
                        // name='price'
                        {...register("price", { required: true })}
                        // value={data?.price}
                        onChange={(e) => setFoodPrice(e.target.value)}
                      />
                      {errors.price && (
                        <span className='text-danger'>
                          {ThisFieldisrequired}
                        </span>
                      )}
                    </div>

                    <div className='form-group mb-3 col-lg-4'>
                      <label>{foodIsDiscountAny} </label>

                      <div>
                        <label>
                          <input
                            type='radio'
                            value='NoDiscount'
                            checked={
                              selectedIsDiscountAnyOption === "NoDiscount"
                            }
                            onChange={handleIsDiscountAnyOptionChange}
                          />
                          {foodIsDiscountOptionNoDiscount}
                        </label>
                      </div>
                      <div>
                        <label>
                          <input
                            type='radio'
                            value='DiscountPrice'
                            checked={
                              selectedIsDiscountAnyOption === "DiscountPrice"
                            }
                            onChange={handleIsDiscountAnyOptionChange}
                          />
                          {foodIsDiscountOptionPriceDiscountTk}
                        </label>
                      </div>
                      <div>
                        <label>
                          <input
                            type='radio'
                            value='DiscountPercentage'
                            checked={
                              selectedIsDiscountAnyOption ===
                              "DiscountPercentage"
                            }
                            onChange={handleIsDiscountAnyOptionChange}
                          />
                          {
                            foodIsDiscountOptionPriceDiscountPercentage
                          }
                        </label>
                      </div>
                    </div>

                    {selectedIsDiscountAnyOption === "DiscountPrice" && (
                      <>
                        <div className='form-group mb-3 col-lg-4'>
                          <label>{foodDiscountPrice}</label>
                          <input
                            type='number'
                            className='form-control input-default'
                            placeholder={foodDiscountPrice}
                            label='discountPrice:'
                            min={0}
                            name='discountPrice'
                            value={discountPrice}

                            onChange={handleDiscountPrice}
                          />
                        </div>
                        <div className='form-group mb-3 col-lg-4'>
                          <label>{foodPriceAerDiscount} </label>
                          <input
                            type='text'
                            className='form-control input-default'
                            placeholder={foodPriceAerDiscount}
                            label=':'
                            value={foodPriceAfterDiscount}
                          />
                        </div>
                      </>
                    )}

                    {selectedIsDiscountAnyOption === "DiscountPercentage" && (
                      <>
                        <div className='form-group mb-3 col-lg-4'>
                          <label>{foodDiscountPercentage}</label>
                          <input
                            type='number'
                            className='form-control input-default'
                            placeholder={foodDiscountPercentage}
                            label='discountPercentage:'
                            name='discountPercentage'
                            min={1}
                            value={discountPercentage}
                            onChange={handleDiscountPercentage}
                          />
                        </div>
                        <div className='form-group mb-3 col-lg-4'>
                          <label>{foodPriceAfterDiscount} </label>
                          <input
                            type='text'
                            className='form-control input-default'
                            placeholder={foodPriceAfterDiscount}
                            label=':'
                            value={foodPriceAfterDiscount}
                          />
                        </div>
                      </>
                    )}
                  </div>

                  {insCatData !== "INSTANT" &&
                    (selectedIsDiscountAnyOption === "DiscountPrice" ||
                      selectedIsDiscountAnyOption === "DiscountPercentage") ? (
                    <div className='row'>
                      <div className='form-group mb-3 col-lg-4'>
                        <label>{discountStart}</label>
                        <input
                          type='date'
                          className='form-control input-default'
                          placeholder={discountStart}
                          label='startdate:'
                          name='startdate'
                          min={new Date().toISOString().split('T')[0]}
                          {...register("startdate", { required: false })}
                        />
                      </div>

                      <div className='form-group mb-3 col-lg-4'>
                        <label>{discountEnd}</label>
                        <input
                          type='date'
                          className='form-control input-default'
                          placeholder={discountEnd}
                          label='enddate:'
                          name='enddate'
                          min={watch("startdate")|| new Date().toISOString().split("T")[0]}

                          {...register("enddate", { required: false })}
                        />
                      </div>
                    </div>
                  ) : null}

                  <div className='row'>
                    <div className='form-group mb-3  col-lg-4'>
                      <label>{category}</label>
                      <select
                        defaultValue={"option"}
                        // name='category'
                        {...register("category", { required: true })}
                        // onClick={getCat}
                        onChange={(event) => setcat(event.target.value)}
                        // value={currentFruit}
                        className='form-control'
                      >
                        <option value='option' disabled>
                          Select Category
                        </option>

                        {catID?.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.categoryName}
                          </option>
                        ))}
                      </select>
                      {errors.category && (
                        <span className='text-danger'>
                          {ThisFieldisrequired}
                        </span>
                      )}
                    </div>
                    <div className='form-group mb-3  col-lg-4'>
                      <label>{subcateg}</label>
                      <select
                        // defaultValue={"option"}
                        // name='subcategory'
                        className='form-control'
                        {...register("subcategory", { required: false })}
                      >
                        <option value='option' disabled>
                          Select Sub Category
                        </option>

                        {subcat?.map((item, index) => (
                          <option key={index} value={item?._id}>
                            {item?.categoryName}
                          </option>
                        ))}
                      </select>
                      {errors.subcategory && (
                        <span className='text-danger'>
                          {ThisFieldisrequired}
                        </span>
                      )}
                    </div>
                  </div>

                  {/* <div className="row d-none">
                    <div className="form-group mb-3 col-lg-4">
                      <label>{labels.foodMinOrderQuan.bn}</label>
                      <input
                        type="number"
                        className="form-control input-default "
                        placeholder={labels.foodMinOrderQuan.bn}
                        label="minimumquantity:"
                        name="minimumquantity"
                      />
                    </div>
                  </div> */}

                  <div className='row d-none'>
                    <div className='form-group mb-3 col-lg-3'>
                      <label>{metatitle}</label>
                      <input
                        type='text'
                        className='form-control input-default '
                        placeholder={metatitle}
                        label='metaTitle:'
                        // name='metaTitle'
                        {...register("metaTitle", { required: false })}
                      // value={data?.price}
                      // onChange={handleChange}
                      />
                    </div>
                    <div className='form-group mb-3 col-lg-3'>
                      <label>{metaDescription}</label>
                      <input
                        type='text'
                        className='form-control input-default '
                        placeholder={metaDescription}
                        label='metaDescription'
                        // name='metaDescription'
                        {...register("metaDescription", { required: false })}
                      // value={data?.price}
                      // onChange={handleChange}
                      />
                    </div>
                    <div className='form-group mb-3 col-lg-3'>
                      <label>{metaKeywords}</label>
                      <input
                        type='text'
                        className='form-control input-default '
                        placeholder={metaKeywords}
                        label='metaKeywords'
                        // name='metaKeywords'
                        {...register("metaKeywords", { required: false })}
                      // value={data?.price}
                      // onChange={handleChange}
                      />
                    </div>
                    <div className='col-lg-3'>
                      <label>{metaImg}</label>
                      <input
                        type='file'
                        className='form-control input-default '
                        placeholder={metaImg}
                        label='metaImage'
                        name='metaImage'
                        // value={data?.price}
                        onChange={handleMetaImage}
                      />
                    </div>
                  </div>

                  <div className='mt-3 row'>
                    <label>{addditional}</label>
                    {taq.map((item, index) => (
                      <div className='col-lg-3 col-md-3 '>
                        <span key={index}>
                          {item.status === true ? (
                            <span className='form-check custom-checkbox mb-3 ms-3 checkbox-info d-flex'>
                              <input
                                onChange={(e) => {
                                  handleCheck(e);
                                }}
                                type='checkbox'
                                name={item.tagName}
                                // {...register(`${item.tagName}`, {
                                //   // required: true,
                                // })}
                                value={item.tagName}
                                className='form-check-input'
                                id={`customCheckBox${index}`}
                              />

                              <label
                                className='form-check-label'
                                htmlFor={`customCheckBox${index}`}
                              >
                                {item.tagName}
                              </label>
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    ))}
                  </div>

                  {insCatData === "INSTANT" ? (
                    <div className='mt-3 row'>
                      <div className='form-group mb-3  col-lg-3'>
                        <label>

                          {itemactive}
                        </label>
                      </div>
                      <div className='form-group  col-lg-4'>
                        <select
                          // name='foodActiveForHours'
                          {...register("foodActiveForHours", {
                            required: true,
                          })}
                          className='form-control input-default '
                          defaultValue='12'
                        // onChange={handleInCa}
                        >
                          <option value='12'>{twelvhrs}</option>
                          <option value='24'>{twentyhrs}</option>
                          <option value='36'>{thirtyhrs}</option>

                          <option value='1000'>Unlimited</option>

                        </select>
                        {errors.foodActiveForHours && (
                          <span className='text-danger'>
                            {ThisFieldisrequired}
                          </span>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {insCatData == "PREORDER" || insCatData=="CATERING" ? (
                    <div className='mt-3 row'>
                      <div className='form-group mb-3  col-lg-3'>
                        <label> Buyer has to offer at least before</label>
                      </div>
                      <div className='form-group  col-lg-4'>
                        <select
                          // name='foodOrderBeforeTime'
                          {...register("foodOrderBeforeTime", {
                            required: true,
                          })}
                          className='form-control input-default '
                          defaultValue='1 day'
                        // onChange={handleInCa}
                        >
                          <option value='1 day'>১ দিন</option>
                          <option value='2 day'>২ দিন</option>
                          <option value='18 hours'>১ ঘন্টা </option>
                          <option value='18 hours'>২ ঘন্টা </option>
                          <option value='18 hours'>৩ ঘন্টা </option>
                          <option value='18 hours'>৪ ঘন্টা </option>
                          <option value='18 hours'>৫ ঘন্টা </option>
                          <option value='12 hours'>১২ ঘন্টা </option>
                          <option value='18 hours'>১৮ ঘন্টা </option>

                          {/* <option value='400 hours'>Unlimited</option> */}

                        </select>
                        {errors.foodOrderBeforeTime && (
                          <span className='text-danger'>
                            {ThisFieldisrequired}
                          </span>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className='mt-3 row'>
                    <div className='form-group mb-3  col-lg-3'>
                      <Button style={{ marginLeft: 25 }} type='submit'>
                        {" "}
                        {CreatFood}
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};
export default CreateFood;
