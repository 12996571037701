import React, { useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import Logo from "../../../images/ownfood-logo.png";

export function  NavMenuToggle(){
	setTimeout(()=>{
		let mainwrapper = document.querySelector("#main-wrapper");
		if(mainwrapper.classList.contains('menu-toggle')){
			mainwrapper.classList.remove("menu-toggle");
		}else{
			mainwrapper.classList.add("menu-toggle");
		}
	},200);
}

export function  MobileNavMenuToggle(){
	setTimeout(()=>{
		let mainwrapper = document.querySelector("#main-wrapper");
		if(mainwrapper.classList.contains('menu-toggle')){
			mainwrapper.classList.remove("menu-toggle");
		}else{
			mainwrapper.classList.add("menu-toggle");
		}

    const hamburger = document.querySelector(".hamburger");
    if (hamburger.classList.contains('is-active')) {
      hamburger.classList.remove("is-active");
    } else {
      hamburger.classList.add("is-active");
    }
	},200);
}

const NavHader = ({setToggle,toggle}) => {
  // const [toggle, setToggle] = useState(false);
  // console.log(toggle,'toggle');
  const { navigationHader, openMenuToggle,menuToggle ,background } = useContext(
    ThemeContext
  );
  // console.log(menuToggle,1,'toggle');
  return (
    <div className="nav-header ">
     <Link to="/" className="brand-logo d-none d-md-block">
  <img src={Logo} alt="brand logo" className="panel-logo img-fluid" />
</Link>

        
      <div
        className='nav-control'
        onClick={() => {
          setToggle(!toggle);
          // openMenuToggle();
          NavMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className='line'></span>
          <span className='line'></span>
          <span className='line'></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
