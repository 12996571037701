import React, { useState, useEffect, useRef, useContext } from "react";
import { IoLanguageOutline } from "react-icons/io5";
import { Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, redirect, useNavigate } from "react-router-dom";
// import { Redirect } from 'react-router-dom';
import {
  getRiderInfo,
  getSeller,
  logoutUser,
} from "../../../Redux/feature/Authentication/AuthenticationSlice";
import { getUserDetails } from "../../../Helper/SessionHelper";
import {
  useGetRiderInfoQuery,
  useGetSellerInfoQuery,
  useUpdateHandleMutation,
} from "../../../Redux/API/ApiSlice";
import { getSellerAPI } from "../../../API/SellerAPI";
import { FaSignOutAlt } from "react-icons/fa";
import { LanguageContext } from "../../../context/LanguageContext";

const LocationIcon = <i className="fa-solid fa-location-dot mx-2 " />;

function AddSearchSlider() {
  //alert(111);
  let SearchBlog = document.getElementById("Search-Blog");
  let BoxTab = document.getElementById("close-searchbox");
  setTimeout(() => {
    if (BoxTab.classList.contains("active")) {
      return (
        BoxTab.classList.remove("active"), SearchBlog.classList.remove("active")
      );
    } else {
      return BoxTab.classList.add("active"), SearchBlog.classList.add("active");
    }
  }, 100);
}

const Header = ({ onNote }) => {
  // const history = useHistory();
  useEffect(() => {}, []);

  const [approvedSeller, setApprovedSeller] = useState(false);
  const [approvedRider, setApprovedRider] = useState(false);
  const userData = getUserDetails();

  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);

  const { data, isSuccess: userInfoSuccess } = useGetSellerInfoQuery(
    userData?._id
  );
  const { data: riderData, isSuccess: riderSuccess } = useGetRiderInfoQuery(
    userData?._id
  );
  // console.log(riderData, "rider");
  if (userInfoSuccess) {
    // console.log(data);
  }
  const navigate = useNavigate();

  const dispatch = useDispatch();
  dispatch(getSeller(data?.data));
  dispatch(getRiderInfo(riderData?.data));

  let seller = useSelector((state) => state.Authentication.seller);
  let user = useSelector((state) => state.Authentication.user);
  let rider = useSelector((state) => state.Authentication.riderInfo);
  // console.log(user, "user from header", rider, seller);

  const handleLogOut = () => {
    localStorage.removeItem("Token");
    localStorage.removeItem("UserDetails"); // Remove user from local storage

    dispatch(logoutUser());
    navigate("/");

    window.location.reload(true);
  };

  const [MoodData, { isError, isSuccess }] = useUpdateHandleMutation();
  const handleMood = (data) => {
    MoodData({ id: userData._id, data: { userType: data } });
    console.log(isSuccess);
    // redirect("/");
    const timer = setTimeout(() => {
      // history.push("/");

      navigate("/");
      window.location.reload();
    }, 1000); // 1000 milliseconds = 1 second

    // Clear the timer to prevent further reload if needed
    return () => clearTimeout(timer);
    // if(isSuccess){
    //   window.location.reload();
    // }
  };
  const handleMoodBuyer = () => {
    MoodData({ id: userData._id, data: { userType: "buyer" } });
    // redirect("/");
    const timer = setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 500); // 1000 milliseconds = 1 second

    // Clear the timer to prevent further reload if needed
    return () => clearTimeout(timer);
    // if(isSuccess){
    //   window.location.reload();
    // }
    // window.location.reload();
  };
  //const [rightSelect, setRightSelect] = useState('Eng');
  const [selectCountry, setSelectCountry] = useState([
    LocationIcon,
    "Bangladesh",
  ]);
  useEffect(() => {
    if (isSuccess) {
      // Wait for 1 second, then reload the website
      const timer = setTimeout(() => {
        window.location.reload();
      }, 1000); // 1000 milliseconds = 1 second

      // Clear the timer to prevent further reload if needed
      return () => clearTimeout(timer);
    }
  }, []);
  // For fix header
  const [headerFix, setheaderFix] = useState(false);
  useEffect(() => {
    if (seller) {
      // alert('asda')
      //   console.log("fist");
      //   setApprovedSeller(true);
      if (seller[0]?.seller[0]?.sellerApproval == "Approved") {
        setApprovedSeller(true);
        //   alert('asda')
        console.log("fist1");
      }
    }
    if (rider) {
      if (rider[0]?.riderInfo[0]?.riderApproval === "Approved") {
        setApprovedRider(true);
      }
    }
  }, [seller, rider]);
  useEffect(() => {
    setTimeout(function () {
      // Dropdown
      const dropbtn = document.getElementById("droptoggle1");
      //let dropTooglebtn = dropbtn.getAttribute("aria-expanded");
      function toggledrop() {
        return dropbtn.classList.toggle("show");
      }
      dropbtn.addEventListener("click", toggledrop);
    }, 500);

    // for header fix on scroll
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  const nameFilter = (username) => {
    if (window.innerWidth <= 768) return username.slice(0, 10);
    else return username;
  };

  const toggleLanguage = () => {
    setCurrentLanguage(currentLanguage === "en" ? "bn" : "en");
  };

  return (
    <div className={`header ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="container d-block my-0">
            <div className="d-flex align-items-center justify-content-sm-between justify-content-end">
              <div className="header-left">
                <div className="nav-item d-flex align-items-center">
                  {/* You can add a logo here */}
                </div>
              </div>
              <ul className="navbar-nav header-right">
                <li className="custEnBn">
                  <div className="language-switcher d-none d-lg-block">
                    <button
                      onClick={toggleLanguage}
                      className="d-flex align-items-center gap-1 text-black p-2"
                    >
                      {currentLanguage === "bn" ? (
                        <img
                          src="/Assets/countryflag/united-states.png"
                          alt=""
                          width="20"
                          height="20"
                        />
                      ) : (
                        <img
                          src="/Assets/countryflag/bangladesh.png"
                          alt=""
                          width="20"
                          height="20"
                        />
                      )}
                      {currentLanguage === "en" ? "BN" : "EN"}
                    </button>
                  </div>
                </li>
                <li>
                  <Dropdown className="header-profile2">
                    <Dropdown.Toggle
                      as="a"
                      className="nav-link i-false cursor-pointer"
                      id="droptoggle1"
                    >
                      <div className="header-info2 d-flex align-items-center">
                        <div className="d-flex align-items-center sidebar-info">
                          <img
                            src= {userData?.userProfilePhoto[0]?.small?.imageUrl || "https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_1280.png"}
                            alt="Profile"
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                          <div className="ms-2">
                            <h6 className="font-w500 mb-0">
                              {userData?.userFullName
                                ? nameFilter(userData.userFullName)
                                : "Guest"}
                            </h6>
                            <p
                              className=" mb-0 text-center"
                              style={{
                                fontWeight: "bold",
                                color: "black",
                                backgroundColor: "#f2f2f2",
                                padding: "4px 8px",
                                borderRadius: "12px",
                              }}
                            >
                              {seller
                                ? seller[0]?.userType
                                : ""}
                            </p>
                          </div>
                          <i className="fas fa-chevron-down ms-2"></i>
                        </div>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu-end">
                      {seller &&
                      (seller[0]?.userType === "buyer" ||
                        seller[0]?.userType === "rider") &&
                      approvedSeller ? (
                        <button
                          className="ms-2 dropdown-item ai-icon"
                          onClick={() => handleMood("seller")}
                        >
                          Switch to Seller
                        </button>
                      ) : null}

                      {seller &&
                      (seller[0]?.userType === "seller" ||
                        seller[0]?.userType === "rider") ? (
                        <button
                          className="ms-2 dropdown-item ai-icon"
                          onClick={handleMoodBuyer}
                        >
                          Switch to Buyer
                        </button>
                      ) : null}

                      {seller &&
                      rider &&
                      (seller[0]?.userType === "seller" ||
                        seller[0]?.userType === "buyer") &&
                      approvedRider ? (
                        <button
                          className="ms-2 dropdown-item ai-icon"
                          onClick={() => handleMood("rider")}
                        >
                          Switch to Rider
                        </button>
                      ) : null}

                      <Link
                        to="#"
                        className="dropdown-item ai-icon"
                        onClick={handleLogOut}
                      >
                        <FaSignOutAlt />
                        <span className="ms-2">Logout</span>
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>

    // <div className={`header ${headerFix ? "is-fixed" : ""}`}>
    //   <div className="header-content">
    //     <nav className="navbar navbar-expand">
    //       <div className="container d-block my-0">
    //         <div className="d-flex align-items-center justify-content-sm-between justify-content-end">
    //           <div className="header-left">
    //             <div className="nav-item d-flex align-items-center">

    //             </div>
    //           </div>
    //           <ul className="navbar-nav header-right">
    //             <li>
    //               <Dropdown className=" header-profile2 ">
    //                 <Dropdown.Toggle
    //                   as="a"
    //                   className={`nav-link i-false cursor-pointer `}
    //                   id="droptoggle1"
    //                 //onClick={DropBtnblog()}
    //                 >
    //                   <div className="header-info2 d-flex align-items-center">
    //                     {/* <img src={Seller?.userPhoto} alt="" /> */}
    //                     <div className="d-flex align-items-center sidebar-info">
    //                       {/* {userData?.length > 0 ? ( */}
    //                       <img
    //                         src={
    //                           "https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_1280.png"
    //                         }
    //                         alt=""
    //                       />
    //                       {/* ) : null} */}

    //                       <div className="ms-2">
    //                         <h6 className="font-w500 mb-0">{userData?.userFullName ? nameFilter(userData.userFullName) : "Guest"}</h6>
    //                         <p
    //                           className="text-muted mb-0"
    //                           style={{
    //                             fontWeight: "bold", // Bold text for role
    //                             color: "#ff9800", // Orange color for the role
    //                             backgroundColor: "#f2f2f2", // Light background
    //                             padding: "4px 8px", // Padding for spacing
    //                             borderRadius: "12px", // Rounded corners
    //                           }}
    //                         >
    //                           {userData?.userType ? userData.userType : "Role not assigned"}
    //                         </p>
    //                       </div>
    //                       <i className="fas fa-chevron-down"></i>
    //                     </div>
    //                   </div>
    //                 </Dropdown.Toggle>
    //                 <Dropdown.Menu className="dropdown-menu-end">

    //                   {seller &&
    //                     (seller[0]?.userType === "buyer" ||
    //                       seller[0]?.userType === "rider") &&
    //                     approvedSeller ? (
    //                     <button
    //                       className="ms-2 dropdown-item ai-icon"
    //                       onClick={() => handleMood("seller")}
    //                     >
    //                       Switch Seller
    //                     </button>
    //                   ) : null}
    //                   {seller &&
    //                     (seller[0]?.userType === "seller" ||
    //                       seller[0]?.userType === "rider") ? (
    //                     <button
    //                       className="ms-2 dropdown-item ai-icon"
    //                       onClick={handleMoodBuyer}
    //                     >
    //                       Switch Buyer
    //                     </button>
    //                   ) : null}

    //                   {(seller && rider) &&
    //                     (seller[0]?.userType === "seller" ||
    //                       seller[0]?.userType === "buyer") &&
    //                     approvedRider ? (
    //                     <button
    //                       className="ms-2 dropdown-item ai-icon"
    //                       onClick={() => handleMood("rider")}
    //                     >
    //                       Switch Rider
    //                     </button>
    //                   ) : null}

    //                   {/* <Button
    //                     onClick={handleLogOut}
    //                     className='dropdown-item ai-icon'
    //                   >
    //                     LogOut
    //                   </Button> */}
    //                   <Link
    //                     to="#"
    //                     className="dropdown-item ai-icon"
    //                     onClick={handleLogOut}
    //                   >
    //                     <FaSignOutAlt />
    //                     <span className="ms-2">Logout</span>
    //                   </Link>
    //                 </Dropdown.Menu>
    //               </Dropdown>
    //             </li>
    //           </ul>
    //         </div>
    //       </div>
    //     </nav>
    //   </div>
    // </div>
  );
};

export default Header;
