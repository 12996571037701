import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto p-4">
              <h1 className="text-3xl font-bold text-blue-600 mb-4">Privacy Policy</h1>

         <p className="mb-4">Effective from: January 1st, 2023</p>
      <p className="mb-4">
        OwnFood Inc (“us”, “we”, or “our”) operates the 
        <a href="https://www.ownfood.com" className="text-blue-500 hover:underline"> https://www.ownfood.com</a>, 
        <a href="https://www.ownfood.com.bd" className="text-blue-500 hover:underline"> https://www.ownfood.com.bd</a>, 
        <a href="https://www.ownfood.net" className="text-blue-500 hover:underline"> https://www.ownfood.net</a>, and 
        <a href="https://ownfood.co.uk" className="text-blue-500 hover:underline"> https://ownfood.co.uk</a> 
        website and the OwnFood app (the “Service”).
      </p>
      <p className="mb-4">
        This page informs you of our policies regarding the collection, use, 
        and disclosure of personal data when you use our Service and the 
        choices you have associated with that data.
      </p>
      <p className="mb-4">
        We use your data to provide and improve the Service. By using the 
        Service, you agree to the collection and use of information in 
        accordance with this policy. Unless otherwise defined in this 
        Privacy Policy, terms used in this Privacy Policy have the same 
        meanings as in our Terms and Conditions, accessible from 
        <a href="https://www.ownfood.com" className="text-blue-500 hover:underline"> https://www.ownfood.com</a>.
      </p>

      <h2 className="text-2xl font-bold text-blue-600 mb-3">Key Sections:</h2>
      <h3 className="text-xl font-bold text-blue-600 mb-2">Introduction</h3>
      <p className="mb-4">
        OwnFood is a Social Media platform for Food Lovers. Our Vision and 
        Mission is to Promote homemade healthy food to connect individuals who 
        prepare and sell homemade food to potential customers who are looking 
        for delicious and authentic healthy homemade meals. By using our 
        platform self-employment, entrepreneurship, women empowerment, and easy 
        food accessibility will increase, and overall it will boost the economy 
        of a community. Our platform will be used and served for Food Seller, 
        Food Buyer, Food Delivery person or other term called Rider, Blogger, 
        Food Reviewer, any other 3rd party related to food and any other 
        consumer service provider who share common interest with OwnFood.
      </p>

      <h3 className="text-xl font-bold text-blue-600 mb-2">Information We Collect</h3>
      <h4 className="font-bold mb-1">Personal Data:</h4>
      <p className="mb-4">
        Names, Phone Number, Email address, House addresses, payment details, etc.
      </p>
      <h4 className="font-bold mb-1">Non-Personal Data:</h4>
      <p className="mb-4">
        Browser information, cookies, location data, etc.
      </p>
      <h4 className="font-bold mb-1">Data from Third Parties:</h4>
      <p className="mb-4">
        Information obtained from social media logins, third-party partners, 
        or payment processors.
      </p>
      <h4 className="font-bold mb-1">User-Generated Content:</h4>
      <p className="mb-4">
        Reviews, feedback, and communication with the platform.
      </p>
      <h4 className="font-bold mb-1">Usage Data</h4>
      <p className="mb-4">
        We may also collect information on how the Service is accessed and used 
        (“Usage Data”). This Usage Data may include information such as your 
        computer’s Internet Protocol address (e.g. IP address), browser type, 
        browser version, the pages of our Service that you visit, the time and 
        date of your visit, the time spent on those pages, unique device 
        identifiers, and other diagnostic data.
      </p>

      <h3 className="text-xl font-bold text-blue-600 mb-2">Tracking & Cookies Data</h3>
      <p className="mb-4">
        We may use cookies and similar tracking technologies to track the 
        activity on our Service and hold certain information. Cookies are files 
        with a small amount of data which may include an anonymous unique 
        identifier. Cookies are sent to your browser from a website and stored 
        on your device. Tracking technologies also used are beacons, tags, and 
        scripts to collect and track information and to improve and analyze our 
        Service. You can instruct your browser to refuse all cookies or to 
        indicate when a cookie is being sent. However, if you do not accept 
        cookies, you may not be able to use some portions of our Service.
      </p>
      <h4 className="font-bold mb-1">Examples of Cookies we use:</h4>
      <ul className="list-disc list-inside mb-4">
        <li>Session Cookies: We may use Session Cookies to operate our Service.</li>
        <li>Preference Cookies: We may use Preference Cookies to remember your preferences and various settings.</li>
        <li>Security Cookies: We may use Security Cookies for security purposes.</li>
      </ul>

      <h3 className="text-xl font-bold text-blue-600 mb-2">How We Use Your Information</h3>
      <ul className="list-disc list-inside mb-4">
        <li>To provide services (food delivery, seller communication, etc.).</li>
        <li>For payment processing.</li>
        <li>To improve the platform (analytics, customer support, personalization).</li>
        <li>To send notifications, updates, or marketing communications.</li>
        <li>Legal compliance and fraud prevention.</li>
      </ul>

      <h3 className="text-xl font-bold text-blue-600 mb-2">Sharing of Information</h3>
      <ul className="list-disc list-inside mb-4">
        <li>With food sellers/buyers to fulfill orders.</li>
        <li>With delivery person (Rider) and/or partners for order delivery.</li>
        <li>With payment processors to complete transactions.</li>
        <li>With third-party service providers for analytics, customer support, etc.</li>
        <li>Legal reasons: If required by law, to protect rights, safety, or fraud prevention.</li>
      </ul>

      <h3 className="text-xl font-bold text-blue-600 mb-2">How We Protect Your Information</h3>
      <ul className="list-disc list-inside mb-4">
        <li>Encryption of sensitive data (e.g., payment details).</li>
        <li>Secure storage measures.</li>
        <li>Regular security audits and updates.</li>
      </ul>

      <h3 className="text-xl font-bold text-blue-600 mb-2">User Rights</h3>
      <ul className="list-disc list-inside mb-4">
        <li>Right to access, modify, or delete personal data.</li>
        <li>Right to withdraw consent for certain types of data processing.</li>
        <li>How to contact the platform for privacy concerns.</li>
      </ul>

      <h3 className="text-xl font-bold text-blue-600 mb-2">Data Retention</h3>
      <p className="mb-4">
        How long personal data is kept: As long as Seller/Rider provides services 
        (e.g., transaction records, account information). We keep Name and Email 
        address to send email to Buyer, Sellers and Rider for notification, 
        promotional news, and for any other communication. Seller and Rider who 
        are not providing service by deactivating their account, only their Name 
        and Email address will be kept for communication purposes.
      </p>

      <h3 className="text-xl font-bold text-blue-600 mb-2">Children’s Privacy</h3>
      <p className="mb-4">
        The platform should not be used by individuals under a certain age 
        without parental consent (often 16 or 18 depending on local regulations). 
        We do not knowingly collect personally identifiable information from anyone 
        under the age of 18. If you are a parent or guardian and you are aware 
        that your Children have provided us with Personal Data, please contact us. 
        If we become aware that we have collected Personal Data from children 
        without verification of parental consent, we take steps to remove that 
        information from our servers.
      </p>

      <h2 className="text-xl font-semibold mt-4">Third-Party Services</h2>
      <p className="mb-2">
        We may use third-party services for analytics, payment processing, and other functionalities. These third parties have their own privacy policies.
      </p>


      <h2 className="text-xl font-semibold mt-4">Your Rights</h2>
      <p className="mb-2">
        You have the right to access, update, or delete your personal data. Contact us if you wish to exercise these rights.
      </p>

      <h2 className="text-xl font-semibold mt-4">How We Use Your Information</h2>
      <p className="mb-2">
        We use your information to provide and improve our services, process transactions, and communicate with you.
      </p>
      <h3 className="text-xl font-bold text-blue-600 mb-2">Changes to This Privacy Policy</h3>
      <p className="mb-4">
        We may update our Privacy Policy from time to time. We will notify you 
        of any changes by posting the new Privacy Policy on this page. You are 
        advised to review this Privacy Policy periodically for any changes. 
        Changes to this Privacy Policy are effective when they are posted on 
        this page.
      </p>

      <h3 className="text-xl font-bold text-blue-600 mb-2">Contact Us</h3>
      <p className="mb-4">
        If you have any questions about this Privacy Policy, please contact us:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Email: support@ownfood.com</li>
        <li>Phone: +1 (800) 123-4567</li>
      </ul>

      <h3 className="text-xl font-bold text-blue-600 mb-2">Governing Law</h3>
      <p className="mb-4">
        This Privacy Policy is governed by the laws of the State of Michigan, USA. 
        Any disputes arising out of or related to the Service shall be subject to 
        the exclusive jurisdiction of the courts located in Michigan, USA.
      </p>
 


    

      

      
     
    </div>
  )
}

export default PrivacyPolicy