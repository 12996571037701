import React from 'react';
import { Link } from 'react-router-dom';
import './BottomNavBar.css'; // Import your custom CSS

const BottomNavBar = ({ role = '' }) => {

  console.log(role,"bottom-nab")
  const buyerNavItems = [
    { to: '/', icon: 'fas fa-home', label: 'Home' },
    { to: '/order', icon: 'fas fa-search', label: 'Order' },
    // { to: '/notifications', icon: 'fas fa-bell', label: 'Notifications' },
    { to: '/edit-profile', icon: 'fas fa-user', label: 'Profile' }
  ];

  const sellerNavItems = [
    { to: '/', icon: 'fas fa-tachometer-alt', label: 'Dashboard' },
    { to: '/seller-order', icon: 'fas fa-box', label: 'Orders' },
    // { to: '/edit', icon: 'fas fa-utensils', label: 'Kitchen' },
    { to: '/seller-transaction', icon: 'fas fa-chart-line', label: 'Analytics' },
    { to: '/edit-profile', icon: 'fas fa-user', label: 'Profile' }
  ];

  const riderNavItems = [
    { to: '/', icon: 'fas fa-tachometer-alt', label: 'Dashboard' },
    { to: '/all-rider-order', icon:'fas fa-history' , label: 'Deliveries' },
    { to: '/assign-order', icon: 'fas fa-bicycle', label: 'New Order' },
    { to: '/rider-transaction', icon: 'fas fa-wallet', label: 'Earnings' },
    { to: '/edit-profile', icon: 'fas fa-user', label: 'Profile' }
  ];

  const navItems = role === 'buyer' ? buyerNavItems : role === 'seller' ? sellerNavItems : riderNavItems;

  return (
    <div className="bottom-navbar fixed-bottom d-flex justify-content-around py-2 bg-dark">
      {navItems.map((item) => (
        <Link key={item.to} to={item.to} className="nav-item text-center text-white">
          <i className={item.icon}></i>
          <small>{item.label}</small>
        </Link>
      ))}
    </div>
  );
};

export default BottomNavBar;
