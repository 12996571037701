import React, { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";

import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import MainNavSide from "./nav/MainNavSide";
import { useSelector } from "react-redux";
import { getSellerAPI } from "../../API/SellerAPI";
import { getUserDetails } from "../../Helper/SessionHelper";
import { NavMenuToggle } from "./nav/NavHader";
import LocationTracker from "../Rider/LocationTracker";
import BottomBar from "./MobileMenu/BottomBar";
import BottomNavBar from "./MobileMenu/BottomNavBar";
import FloatingBottomNav from "./MobileMenu/FloatingBottomNav";
import DashboardNavbar from "./DasboardMobile/DashboardNavbar";
// import DashboardNavbar from "./DasboardMobile/DashboardNavbar";

const Main = () => {
  const [toggle, setToggle] = useState(false);
  const wrapperRef = useRef(null);
  const { menuToggle, openMenuToggle } = useContext(ThemeContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  
  let seller = useSelector((state) => state.Authentication.seller);
 
  let riderId;
  if (getUserDetails()?.riderInf) {
    riderId = getUserDetails()?.riderInfo[0]?._id;
  }
  return (
    <div
      id='main-wrapper'
      className={`show ${menuToggle ? "menu-toggle" : ""}`}
    >
      <LocationTracker riderId={riderId} />
      <MainNavSide setToggle={setToggle} toggle={toggle} />
      <div
        ref={wrapperRef}
        className='content-body'
        style={{ minHeight: window.screen.height - 45 }}
      >
        <div className='container'>
          <Outlet />
        </div>
      </div>

{/* <BottomBar/> */}
{
// console.log( seller[0]?.userType,"bottom-nab")
}
{isMobile &&
<BottomNavBar role={seller && seller[0]?.userType}/>

}

{/* <FloatingBottomNav/> */}
     {/* sdfgsdfg */}
      <Footer />
    </div>
  );
};

export default Main;
