import React, { useContext, useState } from "react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useGetTransactionSellerQuery } from "../../Redux/API/ApiSlice";
import { AiFillEye } from "react-icons/ai";
import moment from "moment";
// import { Pie } from "react-chartjs-2";
import { Pie, Cell, Tooltip, PieChart } from "recharts";
import { getUserDetails } from "../../Helper/SessionHelper";
import { LanguageContext } from "../../context/LanguageContext";
import labels from "../../translationData/editProfile.json";
import getTranslation from "../../Helper/getTranslationUtility";
const TranListSeller = labels.userDashboard.TranListSeller;

// import PieChart from "../Common/Charts/PieChart";
export default function TransactionListSeller() {
  const [statusBlog, setStatusBlog] = useState([]);
  const performance = [1, 2, 3];
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
  const { data, isSuccess, isLoading } = useGetTransactionSellerQuery(
    getUserDetails()?.sellerIDInfo[0]?._id
  );
  console.log(
    data,
    "data checked from seller",
    getUserDetails()?.sellerIDInfo[0]?._id
  );
  let formattedData;
  if (data?.data?.length > 0 && isSuccess) {
    const originalData = data.data;
    // console.log(originalData);
    formattedData = Object.keys(originalData[0]).map((key) => ({
      name: key,
      value: originalData[0][key],
    }));

    // console.log(formattedData);
  }
  // console.log(formattedData);
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const RADIAN = Math.PI / 180;
  // const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  //   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  //   const x = cx + radius * Math.cos(-midAngle * RADIAN);
  //   const y = cy + radius * Math.sin(-midAngle * RADIAN);

  //   return (
  //     <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
  //       {`${(percent * 100).toFixed(0)}%`}
  //     </text>
  //   );
  // };


   // Function to change the language
 const changeLanguage = (language) => {
  setCurrentLanguage(language);
};

  //Tab labels:
  const sl = getTranslation(
    "sl.tabTitle",
    currentLanguage,
    TranListSeller
  );

  const txnid = getTranslation(
    "txnid.tabTitle",
    currentLanguage,
    TranListSeller
  );
  const Particular = getTranslation(
    "Particular.tabTitle",
    currentLanguage,
    TranListSeller
  );
  const date = getTranslation(
    "date.tabTitle",
    currentLanguage,
    TranListSeller
  );
  const Amount = getTranslation(
    "Amount.tabTitle",
    currentLanguage,
    TranListSeller
  );
  const Days = getTranslation(
    "Days.tabTitle",
    currentLanguage,
    TranListSeller
  );


  return (
    <>
      <div>
        {formattedData && (
          <div className='d-flex justify-content-center'>
            {/* <PieChart labels={"label"} data={[10,20,30]}/> */}
            <PieChart height={400} width={400}>
              <Pie
                dataKey='value'
                data={formattedData}
                cx={200}
                cy={200}
                label
                // label={renderCustomizedLabel}
                outerRadius={150}
                fill='#8884d8'
                isAnimationActive={true}
              >
                {COLORS.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
        )}

        <div
          className='mb-3 d-flex align-items-center  p-4 rounded bg-black'
          style={{ backgroundColor: "white" }}
        >
          {/* <div
            className='input-group search-area2 style-1 w-[75%] border d-flex'
            style={{ marginRight: "15px" }}
          >
            <span className='input-group-text p-0'>
              <Link to={"#"}>
                <svg
                  className='me-1'
                  width='32'
                  height='32'
                  viewBox='0 0 32 32'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z'
                    fill='#FC8019'
                  />
                </svg>
              </Link>
            </span>

            <input
              type='text'
              className='form-control p-2 '
              placeholder='Filter by name, email, phone'
              // value={searchTerm}
              // onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div> */}
{/* 
          <div className='input-group ml-2 pl-2 style-1 w-50'>
            {/* Search filter *
            <Dropdown
              className='notification-select '
              style={{ marginTop: "5px", marginBottom: "5px" }}
            >
              <Dropdown.Toggle
                as='div'
                className='form-control default-select border me-sm-3 m-0 w-auto i-false'
              >
                {/* {dropValue}{" "} 
                <i className='fas fa-chevron-down notification-drop-select'></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  // onClick={() => setDropValue("Date")}
                  eventKey='Activity'
                >
                  Date
                </Dropdown.Item>

                <Dropdown.Item
                  // onClick={() => setDropValue("Ratings")}
                  eventKey='Activity'
                >
                  Ratings
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            
            <Dropdown
              className='notification-select'
              style={{ marginTop: "5px", marginBottom: "5px" }}
            >
              <Dropdown.Toggle
                as='div'
                className='form-control default-select border me-sm-3 m-0 w-auto i-false'
              >
                Sort By Status
                {/* {filter1} 
                <i className='fas fa-chevron-down notification-drop-select'></i>
              </Dropdown.Toggle>
              {/* <Dropdown.Menu>
              <Dropdown.Item onClick={(e) => sortAll()} eventKey="Activity">
                All
              </Dropdown.Item>

              <Dropdown.Item
                onClick={(e) => sortInactive()}
                eventKey="Activity"
              >
                Inactive
              </Dropdown.Item>

              <Dropdown.Item onClick={(e) => sortActive()} eventKey="Activity">
                Active
              </Dropdown.Item>
            </Dropdown.Menu> 

              <Dropdown.Menu>
                <Dropdown.Item
                // onClick={(e) => handlesort("all")}
                // eventKey="Activity"
                >
                  All
                </Dropdown.Item>

                <Dropdown.Item
                // onClick={(e) => handlesort("inactive")}
                // eventKey="Activity"
                >
                  Inactive
                </Dropdown.Item>

                <Dropdown.Item
                // onClick={(e) => handlesort("active")}
                // eventKey="Activity"
                >
                  Active
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/*  Sort by */}
            {/* <Dropdown
            className="notification-select"
            style={{ marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
          >
            <Dropdown.Toggle
              as="div"
              className="form-control default-select border me-sm-3 m-0 w-auto i-false"
            >
              {apiPagination}{" "}
              <i className="fas fa-chevron-down notification-drop-select"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setfilter("1-50")}
                eventKey="Activity"
              >
                1-50
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setfilter("51-100")}
                eventKey="Activity"
              >
                51-100
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setfilter("101-150")}
                eventKey="Activity"
              >
                101-150
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setfilter("151-200")}
                eventKey="Activity"
              >
                151-200
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown
            className="notification-select"
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            <Dropdown.Toggle
              as="div"
              className="form-control default-select border me-sm-3 m-0 w-auto i-false"
            >
              <i
                className="fa-solid fa-location-dot mx-2"
                style={{ color: "orange" }}
              />{" "}
              <i className="fas fa-chevron-down notification-drop-select"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setfilter("1-50")}
                eventKey="Activity"
              >
                Thana
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setfilter("51-100")}
                eventKey="Activity"
              >
                Region
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setfilter("101-150")}
                eventKey="Activity"
              >
                City
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
            {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => setPAGE_SIZE(e.target.value)}
            className="form-control p-2 m-2"
            style={{ width: "50px" }}
          /> */}

            {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => {
              setPAGE_SIZE(e.target.value);
            }}
            className="form-control p-2 m-2 rounded text-center"
            style={{ width: "50px" }}
          /> *
          </div> */}
        </div>

        <div className='card h-auto' style={{ marginTop: 2 }}>
          <div className='card-body p-0'>
            <div className='table-responsive'>
              {
                <table
                  className='table table-list i-table style-1 mb-4 border-0'
                  id='guestTable-all3'
                >
                  <thead>
                    <tr>
                    <th>{sl}</th>
                    <th>{txnid}</th>
                      <th>{Particular}</th>

                      <th>{date}</th>
                      <th>{Amount}</th>
                      <th>{Days}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isSuccess &&
                      data.metaData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <div className='media-bx d-flex py-3 align-items-center'>
                                <div>{index + 1}</div>
                              </div>
                            </td>
                            <td>
                              <div className='media-bx d-flex py-3 align-items-center'>
                                <div>
                                  {item?.id}
                                  {/* <p className="mb-0">1x </p> */}
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className='media-bx d-flex py-3 align-items-center'>
                                <div>
                                  {`Earning from Food Sell OrderID#${item.orderId}`}
                                  {/* <p className="mb-0">1x </p> */}
                                </div>
                              </div>
                            </td>

                            <td>
                              <div>
                                {moment(item?.createdAt).format("MMMM Do YYYY")}
                              </div>
                            </td>

                            <td>
                              <div>
                                <span className='btn btn-primary m-1 p-2'>
                                  {item.credit}
                                </span>
                              </div>
                            </td>

                            {/* <td>
                              <div className='d-flex gap-2'>
                                {!item.avaiableitem && (
                                  <Link to={`/create-withdraw/${item.orderId}`}>
                                    Withdraw
                                  </Link>
                                )}
                              </div>
                            </td> */}
                            <td>
                              <div className='d-flex gap-2'>
                                {!item.avaiableitem && parseInt(item.days)}days
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              }
            </div>
          </div>
        </div>
      </div>
    </>
    // <div className='row'>
    //   {isSuccess &&
    //     data.metaData.map((item) => (
    //       <div className='col-12'>
    //         <div className='row'>
    //           <div className='col'>${item.credit}</div>
    //           <div className='col'>{item.orderId}</div>
    //           <div className='col'>{item.orderId}</div>
    //           <div className='col'>
    //             {!item.avaiableitem && parseInt(item.days)}days
    //           </div>

    //           <div className='col'>
    //             {item.avaiableitem && `withdraw now`}days
    //           </div>
    //         </div>
    //       </div>
    //     ))}
    // </div>
  );
}
